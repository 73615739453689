<template>
  <div class="z-0">
    <header>
      <ConnectWallet />
    </header>
    <div
      class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
    >
      <div class="py-1 max-w-5xl w-full flex-none">
        <div class="p-4">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
      </div>

      <div class="bg-gray-800 py-2 px-3 rounded max-w-5xl w-full flex-none">
        <div class="text-base font-medium">
          <span class="mr-4 font-bold"
            >Transactions related to {{ tx_id.slice(0, 12) }}...</span
          >
        </div>
      </div>
      <div class="flex-glow max-w-5xl w-full">
        <div v-if="!ready" class="flex flex-col">
          <loader class="h-40"></loader>
          <p class="text-center">Loading...</p>
        </div>
        <div v-else>
          <div v-if="table_rows.length == 0">
            <div class="flex flex-col">
              <chameleon class="h-40"></chameleon>
              <p class="text-center">Related transactions do not match</p>
            </div>
          </div>
          <div v-else class="w-full items-center">
            <div
              v-for="t in table_rows"
              v-bind:key="t.id"
              class="flex w-full hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
            >
              <div class="flex-grow flex flex-col sm:flex-row">
                <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                  <router-link
                    class="text-baseline text-blue mr-4"
                    :style="`color: #${t.user_id.slice(-6)};`"
                    v-bind:to="'/user/' + t.user_id"
                    ><i class="fad fa-user mr-1"></i> ...{{
                      t.user_id.slice(-6)
                    }}
                  </router-link>
                  {{ t.createdAt }}
                </div>
                <div class="text-sm sm:text-base">
                  {{ t.remark }}
                </div>
              </div>
              <div
                class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
              >
                <div>
                  {{ t.amount.toFixed(8) }}
                  <img
                    class="inline-block h-4 ml-2"
                    :src="getCurrency[t.currency].logo"
                  />
                </div>
                <div
                  v-bind:class="
                    'text-xs font-semibold inline-block py-1 px-2 rounded  uppercase last:mr-0 ml-2 ' +
                    t.label
                  "
                >
                  {{ t.type }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <StickFooter />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "@/assets/svg/loader.vue";
import chameleon from "@/assets/svg/chameleon.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import info from "@/api/info";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

export default {
  components: {
    loader,
    chameleon,
    ConnectWallet,
    StickFooter,
  },
  data() {
    return {
      tx_id: this.$route.params.tx_id || null,
      ready: false,
      page: 0,
      nomore: false,
      table_rows: [],
      expanded: null,
    };
  },
  computed: mapGetters(["getCurrency"]),
  methods: {
    fixed(a) {
      return func.fixed8(a);
    },
    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    getdata() {
      info
        .txrelation({ tx_id: this.tx_id })
        .then((res) => {
          this.ready = true;

          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            var a = label.type(e.type);
            e.label = a.label;

            this.table_rows.push(e);
          });
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    if (this.tx_id) {
      this.getdata();
    } else {
      this.history_rows = [];
      this.ready = true;
    }
  },
};
</script>
